.base-modal-container {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.4);
}

.base-modal {
    border-radius: 5px;
    padding: 2rem;
    background-color: white;
    min-width: 25%;
    width: auto;
    margin: auto;
}

.base-model .btn {
    display: block;
    margin: auto;
}

.base-modal .btn .delete-cp {
    margin-left: 5px;
    margin-right: 5px;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.form-group input,
.form-group select {
    border: 1px solid black;
    border-radius: 0.3rem;
    padding: 0.3rem;
    font-size: 1rem;
    margin: auto;
}

.form-group label {
    margin-bottom: 0.2rem;
}
