.actions {
    display: flex;
    justify-content: space-around;
}

svg {
    justify-content: space-around;
    cursor: pointer;
}

.parent {
    columns: 2;
}

.child {
    padding: 3px;
}