.navbar {
    padding: 20px;
    display: flex;
    align-items: center;
    max-width: 90%;
    margin: 0 auto;
    border-bottom: 1px solid #f2f2f2;
  }
  .navbar h1 {
    color: #f1356d;
  }
  .navbar .links {
    margin-left: auto;
  }
  .navbar a {
    margin-left: 16px;
    text-decoration: none;
    padding: 6px;
  }
  .navbar a:hover {
    color: #f1356d;
  }