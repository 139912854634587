.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.wrapper {
  padding: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.error {
  background-color: #f8d7da;
  color: #df4759;
  padding: 0.5rem;
  border-radius: 0.3rem;
  margin-bottom: 1rem;
}

.flex-parent-element {
  margin: auto;
  display: flex;
  width: 50%;
}

.flex-child-element {
  flex: 1;
  margin: 10px;
}

.flex-child-element-center {
  display: flex;
  margin: auto;
}

.flex-child-element:first-child {
  margin-right: 20px;
}

.inline-btn {
  margin-right: 15px;
}

.inline-btn-end {
  margin-right: 0px;
}

.infoStyle {
  text-align: left;
}

.readonly-input {
  background-color: lightgray;
}

.percentage-input {
  width: "65px";
  text-align: center;
}