table {
    border-collapse: collapse;
    border-spacing: 0;
    width: auto;
    border: 1px solid #ddd;
    margin: auto;
    table-layout: auto;
    overflow-x: auto;
    overflow-y: auto;
    margin-top: 15px;
}

.actions {
    display: flex;
    justify-content: space-around;
}

.actions svg {
    cursor: pointer;
}

table th, table td {
    text-align: left;
    padding: 16px;
    border: 1px solid #ddd;
}
 
table tr:nth-child(even) {
    background-color: #f2f2f2;
}
 
table tr:hover {
    background-color: #ddd;
}
 
table th, tfoot td {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #4CAF50;
    color: white;
}

ul li {
    display: inline;
    padding-left: 4px;
}