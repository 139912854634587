h2 {
    padding: 5px;
}

.btn {
    margin-top: 1rem;
    border: none;
    background-color: #1d4ed8;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 5px 5px #ccc;
}

.add-new-btn {
    float: right;
    margin-right: 15%;
}

div .table-title {
    display: inline;
}